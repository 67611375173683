import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import React, { memo } from 'react'
import {Link as RouterLink, useHistory} from 'react-router-dom'
import Divider from './Divider'

const Footer = () => {
  const xsMatch = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const smMatch = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const history = useHistory()

  return (
    <Box
      component={'footer'}
      p={2}
      pt={8}
      style={{ background: '#171347', color: 'white', marginTop: 'auto' }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Typography
              variant={'h6'}
              style={{ opacity: 0.8, color: 'white', ...(xsMatch && { textAlign: 'center' }) }}
            >
              <strong>
                Бажаєте створити Інтернет-Храм, отримувати фінансову допомогу та проводити прямі
                трансляції?
              </strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              ...(xsMatch && { justifyContent: 'center' }),
            }}
          >
            <Button onClick={() => {
              if(location.pathname === "/contact"){
                window.scrollBy(0, -250)
              }else{
                history.push(`/contact?order=${new Date().getTime()}`)
              }
            }}>
              Замовити послугу
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ margin: '1.5rem 0' }} />
        <Grid container {...(smMatch && { wrap: 'wrap-reverse' })} spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ ...(smMatch && { display: 'flex', justifyContent: 'center' }) }}
          >
            <Typography variant={'caption'} style={{color: 'white'}}>
              <strong> &copy; ReligionPeace, {new Date().getFullYear()}. </strong>Всі права захищено.
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <nav>
              <List
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: 0 }}
              >
                {[
                  { to: '/terms', name: 'Правила користування' },
                  { to: '/policy', name: 'Політика конфіденційності' },
                  { to: '/cookies', name: 'Файли Cookies' },
                ].map(({ to, name }, i) => (
                  <ListItem key={i} style={{ width: 'auto', paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemText>
                      <Link
                        underline={'hover'}
                        component={RouterLink}
                        to={to}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {name}
                      </Link>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </nav>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default memo(Footer)
