import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://test.religionpeace.live/api/',
});

axiosInstance.interceptors.request.use(function (config){
  config.headers["Accept-Language"] = "ua"

  return config
})

export default axiosInstance
