import React, {useEffect} from 'react';
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";

const Redirect = () => {
    const { search } = useLocation()
    const history = useHistory()

    useEffect(() => {
        const { sync_redirect_email } = qs.parse(search.replace(/^\?/, ''))

        if(sync_redirect_email) history.push(`/?sync_redirect_email=${sync_redirect_email}`)
    })

    return (
        <>
        </>
    );
}

export default Redirect;
