import {
    AppBar,
    Badge,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink as RouterLink, Route, useHistory} from 'react-router-dom'
import logo from '../assets/logo.svg'
import {fetchMoreChurches, setInitialState} from '../store/slices/churches-list-slice'
import FindChurchesByRadiusButton from './FindChurchesByRadiusButton'
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import {getNewNotifications} from "../api/messagingAPI";
import {setCountNewNotifications} from "../store/slices/notifications-slice";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {logout} from "../store/slices/user-slice";
import {deSyncDevice} from "../api/synchronizationAPI";

const routes = [
  { to: '/', name: 'Головна', exact: true },
  { to: '/favorite', name: 'Мої громади', exact: true },
  { to: '/live', name: 'Пряма трансляція', exact: false },
  { to: '/balance', name: 'Баланс добра', exact: true },
  { to: '/notifications', name: 'Сповіщення', exact: false },
  { to: '/about', name: 'Про нас', exact: true },
  { to: '/contact', name: 'Контакти', exact: true },
]

const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: 'Nunito Sans',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.light,
    },
    cursor: 'pointer'
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
  appBar: {
    background: 'white',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  drawerPaper: {
    width: '100%',
  },
  toolbar: {
    height: 90,
  },
  logo: {
    width: 125,
    height: 125,
    objectFit: 'cover',
  },
  menuIconButton: {
    position: 'absolute',
    paddingRight: '10px',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        width: '250px',
        margin: '5px'
    },
    '@media(min-width: 600px)': {
       width: '300px',
       margin: '5px'
    },
  },
  menuSign: {
    padding: 0
  },
  placeholder: {
    height: 70,
  },
  searchNearButton: {
    '@media(max-width: 400px)': {
      width: '150px',
      fontSize: '10px',
    }
  },
  badgeCounter: {
     color: 'white',
  },
  notifyIcon: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover' : {
        transform: 'scale(1.03)'
    },
    '&:active': {
        backgroundColor: 'transparent'
    },
  },
  accountIcon: {
      cursor: 'pointer',
      fontSize: '30px',
      marginRight: '3px',
      '&:hover' : {
          transform: 'scale(1.03)'
      },
      '@media(max-width: 400px)': {
          display: 'none'
      }
  },
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    modalContent: {
        paddingTop: '4px',
        paddingBottom: 0,
    },
    modalText: {
        marginBottom: '5px',
    },
    modalActions: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalForm: {
        borderRadius: '20px',
        textAlign: 'center',
        color: 'black',
    },
    title: {
        padding: 0,
        marginTop: '15px',
    },
    confirmLogout: {
        background: '#ee4253',
        transition: '0.2s',
        '&:hover, &:active': {
            background: '#f32741',
        },
    },
}))

const Header = () => {
  const classes = useStyles()
  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const countNotifications = useSelector(state => state.notifications.countNewNotifications)
  const dispatch = useDispatch()
  const history = useHistory()
  const authorized = useSelector(state => state.user.authorized)
  const userEmail = useSelector(state => state.user.email)
  const [openLogout, setLogoutOpen] = useState(false)

  const handleMainPageLinkClick = () => {
      if(location.pathname === "/"){
          dispatch(setInitialState())
          dispatch(fetchMoreChurches())
      }else{
          dispatch(setInitialState())
      }
  }

    const handleConfirmLogout = () => {
        deSyncDevice().then(() => {
            dispatch(logout())
            setLogoutOpen(false)
        })
    }

    const handleAccountClick = () => {
        if(authorized) {
            setLogoutOpen(true)
        }else {
            history.push("/authorization")
        }
    };

  useEffect(() => {
    getNewNotifications().then((response) => {
      let count = response.count
      dispatch(setCountNewNotifications({count}))
    })
  }, [])

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Container disableGutters>
          <Toolbar className={classes.toolbar}>
            <Link
              component={RouterLink}
              to={'/'}
              style={{ position: 'absolute' }}
              onClick={handleMainPageLinkClick}
            >
              <img src={logo} alt="logo" className={classes.logo} />
            </Link>
            {lgUp && (
              <>
                  <nav style={{margin: '0 auto'}}>
                      <List style={{display: 'flex'}}>
                          {routes.map(({to, name, exact}) => (
                              <ListItem style={{whiteSpace: 'nowrap'}} key={to}>
                                  <ListItemText>
                                      <Link
                                          onClick={to === '/' ? handleMainPageLinkClick : undefined}
                                          to={to}
                                          component={RouterLink}
                                          className={classes.link}
                                          activeClassName={classes.activeLink}
                                          exact={exact}
                                      >
                                          {name}
                                      </Link>
                                  </ListItemText>
                              </ListItem>
                          ))}
                      </List>
                  </nav>
                  <div style={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
                      <Badge badgeContent={countNotifications} max={99} color="primary" classes={{colorPrimary: classes.badgeCounter}}>
                          <NotificationsRoundedIcon fontSize={"medium"} htmlColor={'#f87f30'} className={classes.notifyIcon} onClick={() => {history.push('/notifications')}}/>
                      </Badge>
                      <AccountCircleIcon color="primary" className={classes.accountIcon} onClick={handleAccountClick}/>
                      {/*<LanguageIcon fontSize={"small"} htmlColor={'#f87f30'}/>*/}
                  </div>
              </>
            )}
            {!lgUp && (
              <div className={classes.menuIconButton}
                   style={{justifyContent: location.pathname === "/" ? 'space-around' : 'flex-end',
                            columnGap: location.pathname === "/" ? '0' : '10px'}}>
                  <Badge badgeContent={countNotifications} max={99} color="primary" classes={{colorPrimary: classes.badgeCounter}}>
                      <NotificationsRoundedIcon htmlColor={'#f87f30'} style={{backgroundColor: "transparent", fontSize: '25px'}} onClick={() => history.push('/notifications')}/>
                  </Badge>
                  <AccountCircleIcon color="primary" className={classes.accountIcon} onClick={handleAccountClick}/>
                  <Route exact path={'/'}>
                    <span className={classes.searchNearButton}>
                        <FindChurchesByRadiusButton />
                    </span>
                  </Route>
                  {/*<LanguageIcon fontSize={"small"} htmlColor={'#f87f30'} style={{marginRight: '10px'}}/>*/}
                  <MobileMenuButton openLogout={() => setLogoutOpen(true)}/>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.placeholder} />
        <Dialog
            open={openLogout}
            onClose={() => {
                setLogoutOpen(false)
            }}
            classes={{paper:classes.modalForm}}
        >
            <DialogTitle classes={{root: classes.title}}>ReligionPeace Live</DialogTitle>
            <div style={{
                marginTop: "10px",
                marginBottom: "5px",
                height: 2,
                background: "radial-gradient(ellipse at center, #fc9011 0,hsla(0,0%,100%,0) 75%)"
            }}/>
            <DialogContent>
                <Typography>
                    Ви дійсно бажаєте вийти з облікового запису {userEmail}?
                </Typography>
            </DialogContent>
            <DialogActions classes={{root: classes.modalActions}}>
                <Button
                    color={"primary"}
                    onClick={() => {
                        setLogoutOpen(false)
                    }}
                >
                    Скасувати
                </Button>
                <Button
                    className={classes.confirmLogout}
                    onClick={handleConfirmLogout}
                >
                    Вийти
                </Button>
            </DialogActions>
        </Dialog>;
    </>
  )
}

export default Header

const MobileMenuButton = ({openLogout}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const authorized = useSelector(state => state.user.authorized)
  const history = useHistory()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  // const handleMainPageLinkClick = () => {
  //   dispatch(setInitialState())
  //   dispatch(fetchMoreChurches())
  // }

  return (
    <>
      <IconButton aria-label={'menu button'} color={'primary'} onClick={handleDrawerOpen} classes={{root: classes.menuSign}}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="top"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List component={'nav'}>
          {routes.map(({ to, name }) => (
            <ListItem key={to}>
              <ListItemText style={{ textAlign: 'center' }}>
                <Link
                  onClick={() => {
                    handleDrawerClose()
                  }}
                  to={to}
                  className={classes.link}
                  component={RouterLink}
                  activeClassName={classes.activeLink}
                  exact
                >
                  {name}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
            <ListItem>
                <ListItemText>
                    <Typography
                        onClick={() => {
                            handleDrawerClose()
                            if(authorized) {
                                openLogout()
                            }else {
                                history.push("/authorization")
                            }
                        }}
                        className={classes.link}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '5px'}}>
                        {authorized ? "Вийти" : "Увійти"}
                    </Typography>
                </ListItemText>
            </ListItem>
        </List>
      </Drawer>
    </>
  )
}
