import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: '',
  token: '',
  showCookiesDialog: localStorage.getItem('accept_cookies') === "true",
  email: '',
  balance: 0,
  authorized: false,
  subscribes: [],
  isInitialized: false
}

export const startSetIdAsync = createAction('user/start-set-id-async')

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setId(state, { payload }) {
      state.id = payload
    },
    setToken(state, { payload }) {
      state.token = payload
    },
    setBalance(state, { payload }) {
      state.balance = payload
    },
    login(state, { payload }) {
      state.authorized = true
      state.email = payload
    },
    logout(state) {
      state.authorized = false
      state.email = ''
      state.subscribes = []
    },
    setSubscribe(state, {payload}){
      state.subscribes.push(payload)
    },
    setUnsubscribe(state, {payload}) {
      state.subscribes = state.subscribes.filter(item => {
        return item !== payload
      })
    },
    setInit(state){
      state.isInitialized = true
    },
    setShowDialog(state){
      state.showCookiesDialog = !state.showCookiesDialog
    }
  },
})

export const {
  setId,
  setBalance,
  login,
  logout,
  setSubscribe,
  setUnsubscribe,
  setInit,
  setShowDialog
} = userSlice.actions

export default userSlice.reducer
