import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { enableBatching } from 'redux-batched-actions'
import { createEpicMiddleware } from 'redux-observable'
import churchReducer from '../store/slices/church-slice'
import churchesListReducer from '../store/slices/churches-list-slice'
import userReducer from '../store/slices/user-slice'
import favoriteChurchesListReducer from '../store/slices/favorite-churches-list-slice'
import notificationsReducer from '../store/slices/notifications-slice'
import rootEpic from './epics/index'

const epicMiddleware = createEpicMiddleware()

export const store = configureStore({
  reducer: enableBatching(
    combineReducers({
      churchesList: churchesListReducer,
      user: userReducer,
      church: churchReducer,
      favoriteChurchesList: favoriteChurchesListReducer,
      notifications: notificationsReducer
    })
  ),
  middleware: [epicMiddleware],
  devTools: process.env.NODE_ENV === 'development',
})

epicMiddleware.run(rootEpic)
