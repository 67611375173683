import {Box, Link, makeStyles, Typography} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import image from '../assets/liveStreaming/logo.png'
import Chip from "../components/Chip";

const useStyles = makeStyles(theme => {
    return {
        background: {
            position: 'relative',
            zIndex: 200,
            textAlign: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            // backgroundColor: '#171347',
            backgroundColor: '#fafbfe',
            backgroundSize: '300px',
        },
        rejectLabel: {
            ...theme.typography.h5,
            fontWeight: theme.typography.fontWeightBold,
            padding: '1.5rem',
            color: 'white',
            background: '#f25767',
        },
        attentionBlock: {
            display: 'flex',
            justifyContent: 'center'
        },
        attentionText : {
            lineHeight: '35px'
            // fontSize: '20px',
        },
        coverImage: {
            marginTop: '50px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            padding: 0,
            // width: '100%',
            textAlign: 'center',
        },
        image: {
            width: '320px',
            height: '200px',
            '@media (max-width: 600px)': {
                width: '220px',
                height: '140px'
            },
        },
        successLabel: {
            ...theme.typography.h5,
            fontWeight: theme.typography.fontWeightBold,
            padding: '1.5rem'
        },
    }
})

const RejectLiveStreaming = () => {
    const classes = useStyles()
    const [isHost, setIsHost] = useState(false)
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(() => {
        const role = new URLSearchParams(location.search).getAll('role');
        if(role[0] === 'host'){
            setIsHost(true)
        }else{
            setIsHost(false)
        }
    }, [location]);

    useEffect(() => {
        let listener = () => {
            setHeight(window.innerHeight)
        }
        window.addEventListener('resize', listener)
        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [])

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div className={classes.background} style={{height}}>
            {!isHost ? (
                <Box p={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Chip className={classes.successLabel}><strong>Увага!</strong></Chip>
                    <Typography className={classes.attentionText} variant={'h6'}>
                       Пряма трансляція завершилась.
                       <br/>
                       <Link component={RouterLink} to='/' underline={'always'}>
                           <Typography style={{color: '#f87f30', fontSize: '1rem'}}>повернутися на початкову сторінку…</Typography>
                       </Link>
                    </Typography>
                </Box>
            ) : (
                <Box p={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Chip className={classes.successLabel}><strong>Увага!</strong></Chip>
                    <Typography className={classes.attentionText} variant={'h6'}>
                        Посилання не є дійсним.<br/>
                        Щоб розпочати пряму трансляцію, Вам необхідно
                    </Typography>
                    <Link component={RouterLink} to='/live' underline={'always'}>
                        <Typography style={{color: '#f87f30', fontSize: '1rem'}}>повторно отримати посилання...</Typography>
                    </Link>
                </Box>
            )}
            <div className={classes.coverImage}>
                <img src={image} alt="ReligionPeace Live" className={classes.image}/>
            </div>
        </div>
    )
}

export default RejectLiveStreaming
