import { Box, Button, Grid, Link, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import qs from "qs";
import {useSelector} from "react-redux";

export default function AllowCookiesDialog() {
  const [showDialog, setShowDialog] = useState(false)
  const { search } = useLocation()
  const exceptsShowDialog = useSelector(state => state.user.showCookiesDialog)

  useEffect(() => {
    const { accept_cookies } = qs.parse(search.replace(/^\?/, ''))

    if(accept_cookies !== 'true'
        && location.pathname !==  "/success-payment"
        && location.pathname !==  "/reject-payment"
        && location.pathname !==  "'/live/streaming/'") {
      const acceptCookiesFromLocalStorage = localStorage.getItem('accept_cookies')

      if (acceptCookiesFromLocalStorage !== 'true') {
        setShowDialog(true)
      }

      if(exceptsShowDialog){
        setShowDialog(false)
      }
    }

  }, [exceptsShowDialog])

  const setAcceptCookies = () => {
    localStorage.setItem('accept_cookies', 'true')
  }

  if (!showDialog) return null

  return (
    <Box
      p={3}
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        background: '#171347',
        color: 'white',
      }}
    >
      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2} style={{paddingRight: '20px', paddingLeft: '20px'}}>
        <Grid item style={{marginRight: '50px'}}>
          <Typography style={{color: 'white', textAlign: 'center'}}> Я погоджуюся з:</Typography>
          <ul >
            <li>
              <Link component={RouterLink} to={'/terms'} target={'_blank'}>
                <Typography style={{color: '#f87f30'}}>Правилами користування</Typography>
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to={'/policy'} target={'_blank'}>
                <Typography style={{color: '#f87f30'}}>Політикою конфіденційності</Typography>
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to={'/cookies'} target={'_blank'}>
                <Typography style={{color: '#f87f30'}}>Використанням Файлів Cookies</Typography>
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item justifyContent={'center'}>
          <Button
            onClick={() => {
              setAcceptCookies(true)
              setShowDialog(false)
            }}
          >
            Погоджуюся
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
