import { Box, Link, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Chip from '../components/Chip'

const useStyles = makeStyles(theme => {
  return {
    successLabel: {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightBold,
      padding: '1.5rem',
    },
  }
})

const SuccessPayment = () => {
  const classes = useStyles()
  const [churchId, setChurchId] = useState(null)

  useEffect(() => {
    const orderId = localStorage.getItem('order_id')
    if (!orderId) return

    fetch(`https://religionpeace.live/api/service/order/${orderId}`)
      .then(res => res.json())
      .then(order => {
        setChurchId(order.church_uuid)
      })
  }, [])

  return (
    <Box p={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Chip className={classes.successLabel}>Дякуємо!</Chip>
      <Typography align={'center'} variant={'h6'}>
        На Вашу електронну пошту прийде лист про статус платежу.
      </Typography>
      <Link component={RouterLink} to={churchId ? `/item/${churchId}` : '/'} underline={'always'}>
        <Typography style={{color: '#f87f30'}}>повернутися па початкову сторінку...</Typography>
      </Link>
    </Box>
  )
}

export default SuccessPayment
